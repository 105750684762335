import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
    Grid,
    MenuItem,
    Select,
    TextField,
    FormControl,
    FormHelperText,
    Tooltip,
    ListItemText,
    Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import SnackbarCustom from '../../../Components/Snackbar/Snackbar';
import { tableIcons } from "../../../Utilities/Utility";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import { tableOptionsUser } from "../../../Themes/LightTheme";
import { baseApiURL } from "../../../Utilities/Utility"
export default function UserManagement() {

    const api = axios.create({
        baseURL: 'api/UserManagement'
    })
    const apiRole = axios.create({
        baseURL: 'Role'
    })

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    //For retriving data
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);

    //for role dropdown
    const [itemsRole, setItemsRole] = useState([]);

    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const siemensToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);

    const staticData = [{
        id: 1,
        firstName: 'FirstName1',
        lastName: 'LastName1',
        emailId: 'test@gmail.com',
        gid: 'z004bxrf',
        role: { roleName: 'Admin' },
        roleId: 1,
    },
    {
        id: 2,
        firstName: 'FirstName2',
        lastName: 'LastName2',
        emailId: 'test2@gmail.com',
        gid: 'z004bxrf',
        role: { roleName: 'User' },
        roleId: 3,
    },
    {
        id: 3,
        firstName: 'FirstName3',
        lastName: 'LastName33',
        emailId: 'test3@gmail.com',
        gid: 'z004bxrf',
        role: { roleName: 'External' },
        roleId: 2,
    },
    {
        id: 4,
        firstName: 'FirstName4',
        lastName: 'LastName4',
        emailId: 'test4@gmail.com',
        gid: 'z004bxrf',
        role: { roleName: 'Admin' },
        roleId: 1,
    }];
    //Representing table headers
    var columns = [
        { title: "id", field: "id", hidden: true },
        {
            title: 'FirstName',
            field: 'firstName',
            validate: (rowData) => validateRequiredField(rowData, rowData.firstName, 'firstName'),
        },
        {
            title: 'LastName',
            field: 'lastName',
            validate: (rowData) => validateRequiredField(rowData, rowData.lastName, 'lastName'),
        },
        {
            title: 'Location',
            field: 'location',
            validate: (rowData) => validateRequiredField(rowData, rowData.location, 'location'),
        },
        {
            title: "Role",
            field: "roleId",
            render: (rowData) => <span>{renderRoleValue(rowData.roleId)}</span>,
            editComponent: (props) => getRoleDDLComponent(props),
            validate: (rowData) => validateRole(rowData.roleId),
            customFilterAndSearch: (term, rowData) =>
                renderRoleValue(rowData.roleId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: 'Email Id',
            field: 'emailId',
            validate: (rowData) => validateRequiredField(rowData, rowData.emailId, 'emailId'),
        },
        {
            title: 'GID',
            field: 'gid',
            validate: (rowData) => rowData.roleId == 1 ? validateRequiredField(rowData, rowData.gid, 'gid') : "",
        }
    ];
    function onRoleChange(e, objProps) {
        objProps.onChange(e.target.value);
        // objProps.rowData?.gid = null;
    }
    function getRoleDDLComponent(objProps) {
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    value={objProps.value}
                    onChange={(e) => {
                        onRoleChange(e, objProps);
                    }}
                >
                    {itemsRole.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
            </FormControl>
        );
    }
    function renderRoleValue(value) {
        let obj = {};
        if (value === 6) {
            return "Internal user";
        } else {
            if (itemsRole && itemsRole.length > 0) {
                obj = itemsRole.find((c) => c.value == value);
            }
            if (obj) {
                return obj.label;
            } else {
                return "";
            }
        }
    }
    function validateRole(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }

    useEffect(() => {
        // setTableData(staticData);
        getRoles();
        getUsers();
    }, []);

    
    function getUsers() {
        const options = {
            method: "get",
            headers: {
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };
        return fetch(/*baseApiURL +*/ "api/UserManagement/getAllUsers", options)
            .then((response) => response.json())
            .then(data => {
                setData(data.result);
                console.log('Data', data.result);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
   
    function getRoles() {
        const options = {
            method: "get",
            headers: {
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };
        return fetch(/*baseApiURL +*/ "Role/getAllRoles", options)
            .then((response) => response.json())
            .then(data => {
                setItemsRole(
                    data.result.map(({ roleName, id }) => ({ label: roleName, value: id }))
                );
            })
            .catch(error => {
                console.log("Error in hitting api at page load.")
            })
    }
    //Add New User

    const postUserApi = async (newData, options = {}) => {
        try {
            const response = await api.post('/postUser', newData, options);
            return response;
        } catch (error) {
            throw new Error("API Error");
        }
    };

    // handleRowAdd function
    const handleRowAdd = (newData, resolve, reject) => {
        newData.createdBy = userDetails.id;

        // Additional options (headers, params, etc.)
        const apiOptions = {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };

        // Call the API function with options
        postUserApi(newData, apiOptions)
       
            .then((res) => {
                let dataToAdd = [...data];
                if (res.data.result === 2) {
                    setData([...dataToAdd]);
                    handleSnackOpen("User already exists.", "warning");
                    reject();
                } else {
                    dataToAdd.push(newData);
                    setData(dataToAdd);
                    resolve();
                    getUsers(); // Reload user data
                    handleSnackOpen("User added successfully.", "success");
                }
            })
            .catch((error) => {
                console.error(error); // Log error for debugging
                handleSnackOpen("Failed to add user.", "error");
                reject();
            });
    };
  
    function validateRequiredField(rowData, value, fieldName) {
        let result = true;
        let requiredVal = true;
        let helperTextVal;
        const maxLength = 50;
        const maxGidLength = 8;
        const nameLength = 100;
        const exp = /^[a-zA-Z]*$/;
        const expGid = /^[\/a-zA-Z0-9]*$/;
        const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const expMailDoamin = /\@(.*?)\./;

        if (fieldName === 'firstName') {
            requiredVal = (!value) ? false : true;
            result = exp.test(value);
            helperTextVal = "Invalid Firstname";
            if (value) {
                if (value.length > nameLength) {
                    result = false;
                    helperTextVal = "First Name should not exceeds the Max length: 100";
                }
            }
        }
        else if (fieldName === 'lastName') {
            requiredVal = (!value) ? false : true;
            result = exp.test(value);
            helperTextVal = "Invalid Lastname";
            if (value) {
                if (value.length > nameLength) {
                    result = false;
                    helperTextVal = "Last Name should not exceeds the Max length: 100";
                }
            }
        }
        else if (fieldName === 'location') {
            requiredVal = (!value) ? false : true;
            result = exp.test(value);
            helperTextVal = "Invalid Location";
            if (value) {
                if (value.length > nameLength) {
                    result = false;
                    helperTextVal = "Location should not exceeds the Max length: 100";
                }
            }
        }
        else if (fieldName === 'emailId') {
            requiredVal = (!value) ? false : true;
            result = expEmail.test(value);
            const domainResult = expMailDoamin.exec(value);
            helperTextVal = "Invalid Emailid";

            if (result && (rowData.roleId == 2 || rowData.roleId == 3) && domainResult[1] == "siemens") {
                result = false;
                helperTextVal = "Invalid Email";
            }
            else if (result == false) {
                result = false;
                helperTextVal = "Invalid Email Format";
            }
            //if (value) {
            //    if (value.length > maxLength) {
            //        result = false;
            //        helperTextVal = "Email Id should not exceeds the Max length: 50";
            //    }
            //}
        }
        else if (fieldName === 'gid') {
            requiredVal = (!value) ? false : true;
            result = expGid.test(value);
            const roleSelected = rowData.roleId;
            helperTextVal = roleSelected != 1 ? "Invalid Gid" : "";
            if (value && roleSelected == 1) {
                if (value.length != maxGidLength) {
                    result = false;
                    helperTextVal = "GID should be of 8 digit";
                }
            }
            else {
                result = true;
                helperTextVal = "";
            }
        }

        return !requiredVal
            ? { isValid: false, helperText: "*Required" }
            : !result
                ? { isValid: false, helperText: helperTextVal }
                : { isValid: true, helperText: "" };
    }

    //Updating the row
    const updateUserApi = async (newData, options = {}) => {
        try {
            const response = await api.put('/updateUser', newData, options);
            return response;
        } catch (error) {
            throw new Error("API Error");
        }
    };

    // handleRowUpdate function
    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        newData.ModifiedBy = userDetails.id;

        // Additional options (headers, params, etc.)
        const apiOptions = {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };

        // Call the API function with options
        updateUserApi(newData, apiOptions)

            .then(res => {
                const dataUpdate = [...data];
                if (res.data.result === 2) {
                    setData([...dataUpdate]);
                    handleSnackOpen("User is already exist.", "warning");
                    // resolve();
                    reject();
                }
                else {
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    //resolve();
                    reject();
                    handleSnackOpen("User updated successfully.", "success");
                }
            })
            .catch(error => {
                // resolve();
                reject();
            })
    };


  

    //Deleting user
    const deleteUserApi = async (oldData, options = {}) => {
        try {
            const response = await api.delete('/deleteUser/' + oldData.id, options);
            return response;
        } catch (error) {
            throw new Error("API Error");
        }
    };

  
    //Deleting user
    const handleRowDelete = (oldData, resolve, reject) => {

        // Additional options (headers, params, etc.)
        const apiOptions = {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };

        // Call the API function with options
        deleteUserApi(oldData, apiOptions)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                //resolve();  
                reject();
                handleSnackOpen("User deleted successfully.", "success");
            })
            .catch((error) => {
                //resolve();
                reject();
            });
    };


  

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        //paging: false,
        maxBodyHeight: '600px',
        // exportButton: true,
        // exportAllData: true
    };

    return (
        <div>

            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12} className="d-flex jc-space-bt">
                        <Grid item xs={9} className="text-left ml-1">
                            <h2>User Management</h2>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <MaterialTable
                            title="User List"
                            //title={getTableTitle()}
                            columns={columns}
                            data={data}
                            icons={tableIcons}
                            // isLoading={isLoader}
                            options={tableOptions}
                            editable={{
                                isEditable: (rowData) => rowData.roleId !== 6,
                                isDeletable: (rowData) => rowData.roleId !== 6,
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd(newTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                    </Grid>
                </Grid>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </div>


            {/* <div>
                <MaterialTable
                    title="User Management"
                    data={staticData}
                    columns={columns}
                    // icons={tableIcons}
                    options={{
                        headerStyle: {
                            backgroundColor: "#00193C",
                            color: "#FFFFFF"
                        },
                        //exportButton: {
                        //    csv: true,
                        //    pdf: false
                        //},
                        //exportAllData: true
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                            }),
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                handleRowAdd(newData, resolve, reject);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                handleRowDelete(oldData, resolve, reject)
                            }),
                    }}
                >
                </MaterialTable>

            </div> */}

        </div>
    );
}