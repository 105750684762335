import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import axios from "axios";
import MaterialTable from "material-table";
import Loader from "../../Components/Loader/Loader";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function ViewRTC() {
    const api = axios.create({
        baseURL: "api/RTCAPI",
    });

    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const siemensToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    useEffect(() => {
        getAllDetailList();
        getMachineNumber();
    }, []);

    //For retriving data
    const [data, setData] = useState([]);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    //end snackbar
    const [isLoading, setIsLoading] = useState(false);
    const [matTableData, setMatTableData] = useState([]);
    const getAllDetailList = () => {
        setIsLoading(true);
        const options = {
            method: "get",
            headers: {
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };
        return fetch("api/RTCAPI/getRTCDetails", options)
            .then((response) => response.json())
            .then(function (response) {
                // handleSnackOpen("Complaint Saved Successfully.", "success");
                setIsLoading(false);
                console.log("RTC List", response.result);
                setMatTableData(response.result);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    }
    //const getAllDetailList = () => {
    //    setIsLoading(true);
    //    api
    //        .get("/getRTCDetails/" + userDetails.loginId)
    //        .then(function (response) {
    //            // handleSnackOpen("Complaint Saved Successfully.", "success");
    //            setIsLoading(false);
    //            console.log("RTC List", response.data);
    //            setMatTableData(response.data);
    //        })
    //        .catch((error) => {
    //            console.log("Error in hitting api at page load.", error);
    //            setIsLoading(false);
    //        });
    //};
    //const data = [
    //    {
    //        machineNo: "65279062",
    //        client: "Gupta Electric Company",
    //        efficiencyClass: "IE2",
    //        salesOrder: "3006981707",
    //        salesOrderItem: "200",
    //        produCtcode: "1LE76012DB035AA4-Z",
    //        output: "55.00",
    //        current: "98.00",
    //        volt: "450.00",
    //        speed: "1486",
    //        freq: "50.00",
    //        efficiency: "94.00",
    //        powerFactor: "0.83",
    //        connection: "Delta",
    //        protection: "IP55",
    //        duty: "S1",
    //        insulationClass: "155(F)1100",
    //        frameSize: "280S",
    //        ambientTemp: "IEC 60034-1",
    //        refstd: "IEC 60034-1",
    //        direction: "CLOCKWISE(DE)",
    //        deBearing: "6317-C3",
    //        ndeBearing: "6317-C3",
    //        construction: "IMB3",
    //        tbox: "TB TOP",
    //        motorType: "7CV2280B",
    //        y82: "1B1M022 VOLTAGE ±15%, FREQUENCY ±6%",
    //        vfd: "Yes",
    //        btd: "",
    //        ach: "240V/117W",
    //        ptc: "",
    //        rtc: "",
    //        zcode: "B59+B65+Q07+R62+Y56+Y82",
    //        polarity: "4",
    //        avgRes: "0.0562",
    //        ambTemp: "28",
    //        voltNLTest: "415",
    //        cur1NLTest: "34.1",
    //        cur2NLTest: "33.6",
    //        cur3NLTest: "32.9",
    //        powerNLTest: "1767",
    //        voltLRTest: "71.1",
    //        curLRTest: "98",
    //        inputLRTest: "3930",
    //        voltHVTest: "2",
    //        durationHVTest: "1min",
    //        remarksHVTest: "OK",
    //        insulation: ">50 MOHMS",
    //        date: "25.01.2021",
    //        vibration: "",
    //        edesign: "",
    //        makende: "",
    //        makeNDE: "",
    //        btdRes: "",
    //        achRes: "",
    //        ptcRes: "",
    //        rtdRes: "",
    //        ktyRes: "",
    //        max: "1.89",
    //    },
    //    {
    //        machineNo: "65279062",
    //        client: "Gupta Electric Company",
    //        efficiencyClass: "IE2",
    //        salesOrder: "3006981707",
    //        salesOrderItem: "200",
    //        produCtcode: "1LE76012DB035AA4-Z",
    //        output: "55.00",
    //        current: "98.00",
    //        volt: "450.00",
    //        speed: "1486",
    //        freq: "50.00",
    //        efficiency: "94.00",
    //        powerFactor: "0.83",
    //        connection: "Delta",
    //        protection: "IP55",
    //        duty: "S1",
    //        insulationClass: "155(F)1100",
    //        frameSize: "280S",
    //        ambientTemp: "IEC 60034-1",
    //        refstd: "IEC 60034-1",
    //        direction: "CLOCKWISE(DE)",
    //        deBearing: "6317-C3",
    //        ndeBearing: "6317-C3",
    //        construction: "IMB3",
    //        tbox: "TB TOP",
    //        motorType: "7CV2280B",
    //        y82: "1B1M022 VOLTAGE ±15%, FREQUENCY ±6%",
    //        vfd: "Yes",
    //        btd: "",
    //        ach: "240V/117W",
    //        ptc: "",
    //        rtc: "",
    //        zcode: "B59+B65+Q07+R62+Y56+Y82",
    //        polarity: "4",
    //        avgRes: "0.0562",
    //        ambTemp: "28",
    //        voltNLTest: "415",
    //        cur1NLTest: "34.1",
    //        cur2NLTest: "33.6",
    //        cur3NLTest: "32.9",
    //        powerNLTest: "1767",
    //        voltLRTest: "71.1",
    //        curLRTest: "98",
    //        inputLRTest: "3930",
    //        voltHVTest: "2",
    //        durationHVTest: "1min",
    //        remarksHVTest: "OK",
    //        insulation: ">50 MOHMS",
    //        date: "25.01.2021",
    //        vibration: "",
    //        edesign: "",
    //        makende: "",
    //        makeNDE: "",
    //        btdRes: "",
    //        achRes: "",
    //        ptcRes: "",
    //        rtdRes: "",
    //        ktyRes: "",
    //        max: "1.89",
    //    },
    //];

    const columns = [
        { title: "Machine No", field: "machineNo", editable: "never" },
        { title: "Client", field: "client" },
        { title: "Purchase Order", field: "purchaseOrder" },
        { title: "PO Item", field: "poItem" },
        { title: "Efficiency Class", field: "effiClass" },
        { title: "Sales Order", field: "soNumber" },
        { title: "Sales Order item", field: "soItem" },
        { title: "Product Code", field: "productCode" },
        { title: "Output", field: "output" },
        { title: "Current", field: "current" },
        { title: "Voltage", field: "voltage" },
        { title: "Tolerance For Voltage%", field: "toleranceOfVoltage" },
        { title: "Speed", field: "speed" },
        { title: "Frequency", field: "frequency" },
        { title: "Tolerance For Frequency%", field: "toleranceOfFrequency" },
        { title: "Efficiency %", field: "effiPercentage" },
        { title: "Power Factor", field: "powerFactor" },
        { title: "Connection", field: "connection" },
        { title: "Protection", field: "protection" },
        { title: "Duty", field: "duty" },
        { title: "Insulation Class", field: "insulationClass" },
        { title: "Frame Size", field: "frameSize" },
        { title: "Ambient Temp.", field: "ambientTemp" },
        { title: "Ref.std.", field: "refStd" },
        { title: "Direction", field: "direction" },
        { title: "DE Bearing", field: "deBearing" },
        { title: "NDE Bearing", field: "ndeBearing" },
        { title: "Construction", field: "construction" },
        { title: "T.Box Position", field: "tboxPossition" },
        { title: "Motor TYpe", field: "motorType" },
        { title: "Y82", field: "y82" },
        { title: "VFD", field: "vfd" },
        { title: "BTD", field: "btd" },
        { title: "ACH", field: "ach" },
        { title: "PTC", field: "ptc" },
        { title: "RTD", field: "rtd" },
        { title: "Z Code", field: "zCode" },
        { title: "Polarity", field: "polarity" },
        { title: "Connection", field: "connection" },
        { title: "Avg.Res.", field: "avgRes" },
        { title: "Amb.Temp.", field: "ambTemp" },
        { title: "Voltage NLTest", field: "voltageNlTest" },
        { title: "Current_1 NLTest", field: "current1nltest" },
        { title: "Current_2 NLTest", field: "current2nltest" },
        { title: "Current_3 NLTest", field: "current3nltest" },
        { title: "Input Power NLTest", field: "inputPowerNltest" },
        { title: "Voltage LRTest", field: "voltageLrtest" },
        { title: "Current LRTest", field: "currentLrtest" },
        { title: "Input Power LRTest", field: "inputPowerLrtest" },
        { title: "Voltage HVTest", field: "voltageHvtest" },
        { title: "Duration HVTest", field: "durationHvtest" },
        { title: "Remarks HVTest", field: "remarksHvtest" },
        { title: "Insulation Resistance", field: "insuResistance" },
        { title: "Insulation Resistance Accessories", field: "insulationResistanceAccessories" },
        { title: "Date", field: "date" },
        { title: "Vibration", field: "vibration" },
        { title: "EDesign/Calculation Sheet", field: "edesignCalc" },
        { title: "Make-DE Bearing", field: "makeDeBearing" },
        { title: "Make-NDE Bearing", field: "makeNdeBearing" },
        { title: "BTD Res.", field: "btdRes" },
        { title: "ACH Res.", field: "achRes" },
        { title: "PTC Res.", field: "ptcRes" },
        { title: "RTD Res.", field: "rtdRes" },
        { title: "KTY Res.", field: "ktyRes" },
        { title: "Nameplate Regul ISI ", field: "nameplateRegulIsi" },
        { title: "MAX. % UNBALANCE NLC", field: "maxUnbalanceNlc" },
    ];

    const [rtcDetailsInput, setRtcDetailsInput] = useState("");

    const [machineNoSearchStringUI, setMachineNoSearchStringUI] = useState("");
    const [clientNameVal, setClientNameVal] = useState("");

    const inputFieldsChange = (e) => {
        const fieldVal = e.target.value;
        const fieldName = e.target.name;
        setRtcDetailsInput((rtcDetailsInput) => ({
            ...rtcDetailsInput,
            [fieldName]: fieldVal,
        }));

        switch (fieldName) {
            case "machineNoSearchString":
                setMachineNoSearchStringUI(fieldVal);
                break;
            default:
                break;
        }
    };

    const machineNorChange = (event, value) => {
        setRtcDetailsInput((rtcDetailsInput) => ({
            ...rtcDetailsInput,
            machineNoSearchString: value,
        }));
        setMachineNoSearchStringUI(value);
    };

    const [canShowMaterialTable, setCanShowMaterialTable] = useState(false);
    //const searchComplaint = () => {
    //    console.log("Machine Number", machineNoSearchStringUI);
    //    console.log("Client Name", clientNameVal);

    //    setIsLoading(true);
    //    setCanShowMaterialTable(true);
    //    let searchArgs = {
    //        MachineNumber:
    //            machineNoSearchStringUI != null || machineNoSearchStringUI != undefined
    //                ? machineNoSearchStringUI
    //                : "",
    //        // MachineNo: 652732442,
    //        Client: clientNameVal,
    //    };

    //    api
    //        .post("/SearchRoutineList", searchArgs)
    //        .then(function (response) {
    //            setMatTableData(response.data);
    //            console.log("RTC List Details", response.data);
    //            setIsLoading(false);
    //        })
    //        .catch(function (error) {
    //            setIsLoading(false);
    //        });
    //};

    const searchComplaint = () => {
        console.log("Machine Number", machineNoSearchStringUI);
        console.log("Client Name", clientNameVal);

        setIsLoading(true);
        setCanShowMaterialTable(true);
        let searchArgs = {
            MachineNumber:
                machineNoSearchStringUI != null || machineNoSearchStringUI != undefined
                    ? machineNoSearchStringUI
                    : "",
            // MachineNo: 652732442,
            Client: clientNameVal,
        };
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
            body: JSON.stringify(searchArgs),
        };
        return fetch("api/RTCAPI/SearchRoutineList", options)
            .then((response) => response.json())
            .then(function (response) {
                setMatTableData(response.result);
                console.log("RTC List Details", response.result);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const [machineNumberData, setMachineNumberData] = useState([]);
    

    const getMachineNumber = () => {
        setIsLoading(true);
        const options = {
            method: "get",
            headers: {
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };
        return fetch("api/RTCAPI/getClientList", options)
            .then((response) => response.json())
            .then((res) => {
                console.log("MachineNo Master", res.result);
                setMachineNumberData(res.result);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    }


    //Updating the row
    const updateListApi = async (newData, options = {}) => {
        try {
            const response = await api.put('/UpdateList', newData, options);
            return response;
        } catch (error) {
            throw new Error("API Error");
        }
    };

    // handleRowUpdate function
    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        newData.ModifiedBy = userDetails.id;

        // Additional options (headers, params, etc.)
        const apiOptions = {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };

        // Call the API function with options
        updateListApi(newData, apiOptions)
            .then((res) => {
                const dataUpdate = [...data];
                if (res.data.result === 2) {
                    setMatTableData([...dataUpdate]);
                    handleSnackOpen("Row data already exist.", "warning");
                    resolve();
                    reject();
                } else {
                    //const index = oldData.tableData.id;
                    //dataUpdate[index] = newData;
                    //setMatTableData([...dataUpdate]);
                    getAllDetailList();
                    resolve();
                    reject();
                    console.log("success");
                    handleSnackOpen("Row updated successfully.", "success");
                }
            })
            .catch((error) => {
                resolve();
                reject();
            });
    };


    //Updating the row
    //const handleRowUpdate = (newData, oldData, resolve, reject) => {
    //    newData.ModifiedBy = userDetails.id;
    //    api
    //        .put("/UpdateList", newData)
    //        .then((res) => {
    //            const dataUpdate = [...data];
    //            if (res.data === 2) {
    //                setMatTableData([...dataUpdate]);
    //                handleSnackOpen("Row data already exist.", "warning");
    //                resolve();
    //                reject();
    //            } else {
    //                //const index = oldData.tableData.id;
    //                //dataUpdate[index] = newData;
    //                //setMatTableData([...dataUpdate]);
    //                getAllDetailList();
    //                resolve();
    //                reject();
    //                console.log("success");
    //                handleSnackOpen("Row updated successfully.", "success");
    //            }
    //        })
    //        .catch((error) => {
    //            resolve();
    //            reject();
    //        });
    //};

    //Deleting list
    //const handleRowDelete = (oldData, resolve, reject) => {
    //    api
    //        .delete("/deleteList/" + oldData.rtcId)
    //        .then((res) => {
    //            const dataDelete = [...data];
    //            const index = oldData.tableData.id;
    //            dataDelete.splice(index, 1);
    //            setMatTableData([...dataDelete]);
    //            resolve();
    //            reject();
    //            handleSnackOpen("Row deleted successfully.", "success");
    //        })
    //        .catch((error) => {
    //            resolve();
    //            reject();
    //        });
    //};

    //Deleting List data
    const deleteListApi = async (oldData, options = {}) => {
        try {
            const response = await api.delete('/deleteList/' + oldData.rtcId, options);
            return response;
        } catch (error) {
            throw new Error("API Error");
        }
    };


    //Deleting list
    const handleRowDelete = (oldData, resolve, reject) => {

        // Additional options (headers, params, etc.)
        const apiOptions = {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };

        // Call the API function with options
        deleteListApi(oldData, apiOptions)
            .then((res) => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setMatTableData([...dataDelete]);
                resolve();
                reject();
                handleSnackOpen("Row deleted successfully.", "success");
            })
            .catch((error) => {
                resolve();
                reject();
            });
    };



    function machineNumberOnChange(event) {
        console.log("Testbox value", event.target.value);
        setMachineNoSearchStringUI(event.target.value);
    }
    function clientNameOnChange(e) {
        console.log("Testbox value", e.target.value);
        setClientNameVal(e.target.value);
    }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid container spacing={2} item xs={12} className="d-flex jc-space-bt">
                    <Grid item xs={12} className="text-left ml-1">
                        <h2>View RTC</h2>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-05">
                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            className=""
                            fullWidth
                            name="machineNumber"
                            label="Enter Machine Number"
                            variant="filled"
                            type="text"
                            value={machineNoSearchStringUI}
                            onChange={machineNumberOnChange}
                            id="machineNumber"
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            className=""
                            fullWidth
                            name="companyName"
                            label="Enter Company Name"
                            variant="filled"
                            type="text"
                            value={clientNameVal}
                            onChange={clientNameOnChange}
                            id="machineNumber"
                        />
                    </Grid>

                    {/* <Grid item xs={12} sm={4} md={3} className="customerField">
            <Autocomplete
              options={machineNumberData}
              getOptionLabel={(option) => option}
              id="debug"
              clearOnEscape={true}
              debug
              value={machineNoSearchStringUI}
              onChange={machineNorChange}
              renderInput={(params) => (
                <TextField {...params} label="Machine Number" margin="none" />
              )}
            />
          </Grid> */}
                    <Grid item xs={12} md={2} sm={2} fullWidth>
                        <Button
                            className="pt-button--primary bulk-upload-submit-btn"
                            type="button"
                            onClick={searchComplaint}
                            fullWidth
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title="User List"
                        columns={columns}
                        data={matTableData}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        options={tableOptionsUser}
                        editable={{
                            onRowUpdate: (newTableData, oldTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowUpdate(newTableData, oldTableData, resolve, reject);
                                }),
                            onRowDelete: (oldTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowDelete(oldTableData, resolve, reject);
                                }),
                        }}
                    />
                </Grid>
            </Grid>
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </div>
    );
}
